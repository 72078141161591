<template>
  <div
    class="my_structure"
    v-if="translateData"
  >
    <div class="my_structure__title">{{ this.translateData.my_structure }}</div>
    <div class="my_structure__select-wrap">
      <div
        class="my_structure__select"
        @click="openSelect"
        v-click-outside="closeSelect"
      >
        <div class="my_structure__select-plasholder">
          {{ this.translateData.show_people }} {{ this.translateData.level }} {{ this.level }}
        </div>
        <SelectIcon />
      </div>
      <div
        class="my_structure__select-body"
        v-if="this.selectOpen"
      >
        <div
          v-for="i in 6"
          :key="i"
        >
          <div
            class="my_structure__select-body-list"
            @click="selected(i)"
          >
            {{ this.translateData.show_people }} {{ i }} {{ this.translateData.level }}
          </div>
        </div>
      </div>
    </div>
    <div class="my_structure__table">
      <div
        class="my_structure__row my_structure__row-back"
        v-if="this.backData.length > 0"
      >
        <ArrowSmall @click="goBack" />
        <div
          v-for="item in this.backData"
          :key="item"
          class="my_structure__col-wrap"
        >
          <div class="my_structure__col my_structure__col-back">{{ item }}</div>
          <SelectIcon v-if="this.backData.length > 1" />
        </div>
      </div>
      <div
        class="my_structure__row"
        v-if="this.myStructureData.length > 0"
      >
        <div class="my_structure__col my_structure__col-id">
          {{ this.translateData.id }}
        </div>
        <div class="my_structure__col">{{ this.translateData.name }}</div>
        <div class="my_structure__col">{{ this.translateData.number_of_attracted }}</div>
      </div>
      <div
        v-for="data in this.myStructureData"
        :key="data.id"
      >
        <div
          class="my_structure__row my_structure__body-row"
          v-if="data.referral_count > 0"
          @click="this.goToNexStep(data.referral_id, data.username)"
        >
          <div class="my_structure__col my_structure__col-id">
            {{ data.row_number }}
          </div>
          <div class="my_structure__col">{{ data.username }}</div>
          <div class="my_structure__col">{{ data.referral_count }}</div>
        </div>
        <div
          v-else
          class="my_structure__row my_structure__body-row my_structure__body-row-none"
        >
          <div class="my_structure__col my_structure__col-id">
            {{ data.row_number }}
          </div>
          <div class="my_structure__col">{{ data.username }}</div>
          <div class="my_structure__col">{{ data.referral_count }}</div>
        </div>
      </div>
      <div v-if="this.myStructureData.length === 0">
        <div class="my_structure__row my_structure__body-row-empty">
          {{ this.translateData.empty_structure }}
        </div>
      </div>
    </div>
    <div
      v-if="myStructureMeta?.last_page != undefined"
      class="my_structure__pagination"
    >
      <div class="counter">
        {{myStructureMeta.current_page}} из {{myStructureMeta.last_page}}
      </div>
      <div class="pagination">
        <div
          @click="handlePage(page - 1)"
          v-if="myStructureMeta.current_page != 1"
          class="nav prev"
        >
          <ArrowSmall />
        </div>
        <div
          @click="handlePage(page + 1)"
          v-if="myStructureMeta.current_page != myStructureMeta.last_page"
          class="nav next"
        >
          <ArrowSmall />
        </div>
      </div>
    </div>
    <RefLink />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import ArrowSmall from '@/assets/svg/arrow-small.svg?inline'

import RefLink from '@/components/molecules/RefLink.vue'
import SelectIcon from '@/assets/svg/select-icon.svg?inline'

export default defineComponent({
  name: 'HistorySection',
  data () {
    return {
      translateData: {},
      page: 1,
      historyMeta: {},
      myStructureData: {},
      level: '',
      selectOpen: false,
      id: '',
      myStructureMeta: null,
      backData: [],
      routeCountBack: 1
    }
  },
  components: {
    RefLink,
    SelectIcon,
    ArrowSmall
  },
  mounted () {
    this.translate()
    this.id = ''
    this.level = '1'
    this.getReferrals(this.id, this.level)
  },
  watch: {
    '$route.query.referral_id': function (newReferralId, oldReferralId) {
      if (newReferralId !== oldReferralId) {
        this.getReferrals(this.$route.query.referral_id, this.$route.query.level)
        this.level = this.$route.query.level
      }
    }
  },
  methods: {
    translate (data) {
      data = []
      data.push('transaction_hitory', 'id', 'name', 'number_of_attracted', 'my_structure', 'show_people', 'level', 'empty_structure', 'default_sort')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    getReferrals (id, level) {
      let data = ''
      if (id !== '') {
        data = 'referral_id=' + id
      }
      if (this.level !== '') {
        data = data + '&level=' + level
      }
      this.$store.dispatch('getPayments/getReferrals', data).then(
        (response) => {
          this.myStructureData = response.data
          this.myStructureMeta = response.meta
          this.level = this.myStructureData[0]?.level
          this.$router.push('/dashboard/my-structure?' + 'referral_id=' + id + '&level=' + this.level)
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToNexStep (id, name) {
      this.leval = 1
      if (this.leval <= 6) {
        this.getReferrals(id, this.leval)
        this.backData.push(name)
      }
    },
    handlePage (page) {
      this.page = page
      this.getReferrals()
    },
    openSelect () {
      this.selectOpen = true
    },
    closeSelect () {
      this.selectOpen = false
    },
    selected (i) {
      this.id = ''
      if (this.$route.query.referral_id !== '') {
        this.id = this.$route.query.referral_id
        this.routeCountBack++
      }
      this.level = i
      this.selectOpen = false
      this.getReferrals(this.id, this.level)
    },
    goBack () {
      console.log(this.routeCountBack)
      this.$router.go(-(this.routeCountBack))
      if (this.backData.length > 0) {
        this.backData.pop()
        this.routeCountBack = 1
      }
    }
  }
})
</script>

<style lang='scss' scoped>
.my_structure {
  padding: 80px 5% 0 45px;

  &__title {
    font-size: 24px;
    text-align: left;
    padding-bottom: 20px;
  }

  &__table {
    border-radius: 10px;
    margin-top: 15px;
    border: 1px solid rgba(178, 180, 185, 0.78);
    width: 62vw;
  }

  &__row {
    display: flex;
    padding: 0 40px;
    fill: #F3F4F5;
    background: #F3F4F5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid rgba(178, 180, 185, 0.78);

    + .my_structure__row {
      border-radius: 0;
    }
    &:last-child {
      border: none;
    }
  }

  &__row-empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  &__col-wrap {
    width: auto;
    display: flex;
    align-items: center;
    position: relative;
    right: 30px;
  }

  &__row-back {
    display: flex;
    align-items: center;
    background: transparent;

    svg {
      position: relative;
    right: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__body-row {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__body-row-empty {
    height: 400px;
    width: 100%;
    background: transparent;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 300;
  }

  &__col-back {
    margin-left: 15px;
    width: 100% !important;
    color: #272AE5 !important;
    font-size: 14px;

    + svg {
      transform: rotate(270deg);
      position: relative;
      top: 0;
      width: 14px;
      left: 5px;
    }
  }

  &__select {
    border-radius: 5px;
border: 1px solid #B2B4B9;
    width: 205px;
    height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    background: #ffffff;

    svg {
      position: absolute;
      right: 20px;
    }

    &:hover {
      cursor: pointer;
      color: #272AE5;
    }
  }

  &__select-body {
    position: absolute;
    width: 205px;
    top: 0;
    padding-top: 40px;
    z-index: 0;
    border-radius: 20px 20px 10px 10px;
border: 1px solid #B2B4B9;
background: #FFF;
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }

  &__select-plasholder {
    padding-left: 20px;
  }

  &__select-body-list {
    text-align: left;
    padding: 5px 20px;

    &:hover {
      cursor: pointer;
      color: #272AE5;
    }
  }

  &__select-wrap {
    position: relative;
    z-index: 1;
  }

  &__col {
    display: flex;
    width: 35%;
    padding: 10px 0;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__body-row {
    &:hover {
      transition: 0.5s;
      background: #272AE5;
      cursor: pointer;
      border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    }
  }

  &__body-row-none {
    &:hover {
      cursor: pointer;
    }
  }

  &__col-icon {
    padding-right: 5px;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0 75px 0;

    .pagination {
      display: flex;
      padding-left: 20px;

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-left: 10px;
        background: #15181b;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          border-radius: 10px;
        }

        &.next {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__col-id {
    width: 20%;
  }

  .accrualType {
    border: 2px solid $thirdColor;
    color: $thirdColor;
  }
}

@media (max-width: $break-xlg) {
  .my_structure {
    padding: 30px 20px 200px;

    &__row {
      padding: 0 20px;
    }
    &__table {
      width: 100%;
    }
    &__select {
      width: 100%;
    }
  }
}

@media (max-width: $break-sm) {
  .my_structure {
    padding: 80px 10px;
    width: 100%;
    background: #F5F5F5;
    height: 330px;

    &__title {
      font-size: 16px;
      padding-bottom: 15px;
    }

    &__nav-item {
      width: 100%;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 1.3;
      height: 48px;
    }

    &__row {
      padding: 0 5px;
    }
    &__row-empty {
      font-size: 16px;
    }

    &__col {
      font-size: 13px;
      flex-wrap: wrap;
      padding: 20px 5px;
    }
    &__select {
      width: 224px;
      height: 40px;
      font-size: 14px;
    }
    &__col-back {
      margin-left: 30px;
    }
    &__row-back {
      svg {
        right: 0;
      }
    }
  }
}
</style>
